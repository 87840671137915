import {IonContent, IonPage, IonButton, useIonRouter} from "@ionic/react";
import {Input} from '../components/form/Input';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {useEffect, useState} from "react";

export const ResetPassword = () => {

    const router = useIonRouter();

    const {register, handleSubmit, watch, control, getValues, formState: {errors}} = useForm({
        defaultValues: {
            "email": "",
        },
        mode: "onChange"
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    const onSubmit = async (data: any) => {
        try {
            const auth = getAuth();

            sendPasswordResetEmail(auth, data.email)
                .then(() => {
                    setSuccess(true);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setError(errorCode);
                });
            // await setPersistence(auth, indexedDBLocalPersistence);
            //await signInWithEmailAndPassword(auth, data.email, data.password);
        } catch (e: any) {
            //alert(e.message)
        }
    }

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setError(null);
                setSuccess(false);
                router.push('/login');
            }, 2000);
        }
    }, [success]);

    const {t} = useTranslation();

    return (
        <IonPage>
            <IonContent fullscreen>
                <a href={"https://whergo.fr/"} target={"_blank"} className={"absolute right-4 bottom-4 w-24"}>
                    <img src={"/assets/whergo.png"} className={"w-full h-auto"}/>
                </a>
                <div className={"flex items-center justify-center min-h-full"}>
                    <div className={"grid grid-cols-12 w-full"}>
                        <div className={"col-span-8 col-start-3 lg:col-span-2 lg:col-start-6 flex flex-col mb-20"}>
                            <img src={"/assets/reperes.png"} className={"mb-2"}/>
                            <p className={"text-center text-18 font-light"}>{t('login.desc')}</p>
                        </div>
                        {!success && <form className={"col-span-10 col-start-2 lg:col-span-4 lg:col-start-5"}
                                           onSubmit={handleSubmit(onSubmit)}>
                            <Input control={control} type={"email"} label={"Email"} name="email"
                                   rules={{required: true}}/>
                            {(error && error === 'auth/user-not-found') &&
                                <span className={'col-span-12 text-red-500 text-14 text-center'}>{t('reset.error')}
                                    <b>{getValues('email')}</b></span>}
                            <IonButton className={"uppercase mt-6"} type={"submit"}
                                       expand="block">{t('reset.cta')}</IonButton>
                        </form>}
                        {success && <div className={'col-span-12 text-14 text-center'}>
                            <span>{t('reset.success')}</span> <b>{getValues('email')}</b>
                        </div>}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}