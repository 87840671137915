import {getAuth,SAMLAuthProvider,signInWithRedirect,  signInWithEmailAndPassword} from 'firebase/auth'
import {IonContent, IonPage, IonButton, useIonRouter} from "@ionic/react";
import {useAuthState} from "../utils/firebase";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export const CompanyLogin = () => {
    const router = useIonRouter();

    const { user } = useAuthState()

    useEffect(() => {
        if(user) {
            router.push('/dashboard');
        }
    }, [user]);

    const samlStaubli = async () => {
        const auth = getAuth();
        const provider = new SAMLAuthProvider('saml.saml-staubli');
        try {
            await signInWithRedirect(auth, provider);
        }catch (e) {
            console.log('e',e);
        }
    }

    const {t} = useTranslation();

    return (
        <IonPage>
            <IonContent fullscreen>
                <a href={"https://whergo.fr/"} target={"_blank"} className={"absolute right-4 bottom-4 w-24"}>
                    <img src={"/assets/whergo.png"} className={"w-full h-auto"} />
                </a>
                <div className={"flex items-center justify-center min-h-full"}>
                    <div className={"grid grid-cols-12 w-full"}>
                        <div className={'col-span-12 flex items-center justify-center my-10'}>
                            <IonButton  onClick={samlStaubli}>
                                <span>Se connecter avec</span>
                                <img className={'w-20'} src={'/images/partners/staubli-logo.png'} />
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}