import {
    Redirect,
    Route,
    useLocation,
} from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    setupIonicReact,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonMenu,
    IonMenuToggle
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import {Login} from './pages/Login';
import {useTranslation} from 'react-i18next';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';


/* FIREBASE */
import './utils/firebase.js';
import {useAuthState} from './utils/firebase'
import {AppContextProvider, useAppState} from "./utils/AppContext";
import {lazy, Suspense, useCallback} from "react";
import {getAuth, signOut} from "firebase/auth";

import {menuController} from "@ionic/core";
import Demo from "./pages/Demo";
import {ResetPassword} from "./pages/ResetPassword";
import {CompanyLogin} from "./pages/CompanyLogin";
import {logOutOutline, statsChart} from "ionicons/icons";

const Dashboard = lazy(() => import('./pages/Dashboard'));

setupIonicReact();

const RequireAuth = ({children}: { children: JSX.Element }) => {
    const {isAuthenticated} = useAuthState()
    let location = useLocation();
    if (!isAuthenticated) {
        return <Redirect to={{pathname: '/login'}}/>;
    }
    return children;
}

setupIonicReact({
    rippleEffect: false,
    mode: 'md',
});

const App: React.FC = () => {

    const {ready, user} = useAuthState();
    const {t, i18n} = useTranslation();
    const {state, dispatch} = useAppState();

    /*let hasActions = false;
    if(state && state.entreprise && state.entreprise.options && Array.isArray(state.entreprise.options)) {
        hasActions = state.entreprise.options.includes('actions')
    }

    console.log('hasActions',hasActions,state,user)*/


    const logout = () => {

        const auth = getAuth();
        signOut(auth).then(() => {

        }).catch((error) => {
            // An error happened.
        });

    }

    return (
        <AppContextProvider>
            {!ready && <span>{t('global.loading')}</span>}
            {ready && <IonApp>
                <IonReactRouter>
                    <IonMenu side="start" menuId="main" swipeGesture={false}
                             contentId={"main-content"}>
                        <IonToolbar>
                            <IonTitle>{t('menu.title')}</IonTitle>
                        </IonToolbar>
                        <IonContent>
                            <IonList className={'flex flex-col flex-grow min-h-full'}>
                                <IonItem
                                    onClick={() => menuController.close('main')}
                                    routerLink={"/dashboard/charts"}>
                                    <IonMenuToggle className={'flex items-center'}>
                                        <IonIcon className={'mr-1'} icon={statsChart}></IonIcon>
                                        <IonLabel>{t('menu.charts')}</IonLabel>
                                    </IonMenuToggle>
                                </IonItem>
                                {(user && user.doc && (user.doc.role === 'pole' || user.doc.role === 'admin')) && <IonItem
                                    onClick={() => menuController.close('main')}
                                    routerLink={"/dashboard/actions"}>
                                    <IonMenuToggle>
                                        <IonLabel>{t('menu.actions')}</IonLabel>
                                    </IonMenuToggle>
                                </IonItem>}
                                <IonItem
                                    onClick={() => menuController.close('main')}
                                    routerLink={"/dashboard/fiche/resolutions"}>
                                    <IonMenuToggle>
                                        <IonLabel>{t('menu.resolutions')}</IonLabel>
                                    </IonMenuToggle>
                                </IonItem>
                                <IonItem
                                    onClick={() => menuController.close('main')}
                                    routerLink={"/dashboard/fiche/evaluations"}>
                                    <IonMenuToggle>
                                        <IonLabel>{t('menu.evaluation')}</IonLabel>
                                    </IonMenuToggle>
                                </IonItem>
                                <IonItem className={'cursor-pointer hover:bg-gray-100 mt-auto'}>
                                    <IonMenuToggle
                                        onClick={() => logout()} className={'flex cursor-pointer items-center'}>
                                        <IonLabel>{t('menu.logout')}</IonLabel>
                                        <IonIcon className={'ml-2'} icon={logOutOutline}></IonIcon>
                                    </IonMenuToggle>
                                </IonItem>
                            </IonList>
                        </IonContent>
                    </IonMenu>
                    <IonRouterOutlet id={"main-content"}>
                        <Route path="/dashboard">
                            <RequireAuth>
                                <Suspense
                                    fallback={<div>loading dashboard</div>}>
                                    <Dashboard/>
                                </Suspense>
                            </RequireAuth>
                        </Route>
                        <Route exact path={"/login"} component={Login}/>
                        <Route exact path={"/company"} component={CompanyLogin}/>
                        <Route exact path={"/reset-password"} component={ResetPassword}/>
                        <Route exact path={"/demo"} component={Demo}/>
                        <Redirect exact from="/" to="/dashboard"/>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>}

        </AppContextProvider>
    )
}


export default App;
/*
<Route exact path={"/"}>
                                    <RequireAuth>
                                        <Home />
                                    </RequireAuth>
                                </Route>
 <IonTabs>
                            <IonRouterOutlet>
                                <Switch>

                                </Switch>
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom">
                                <IonTabButton tab="tab1" href="/tab1">
                                    <IonIcon icon={triangle}/>
                                    <IonLabel>Tab 1</IonLabel>
                                </IonTabButton>
                                <IonTabButton tab="tab2" href="/tab2">
                                    <IonIcon icon={ellipse}/>
                                    <IonLabel>Tab 2</IonLabel>
                                </IonTabButton>
                                <IonTabButton tab="tab3" href="/tab3">
                                    <IonIcon icon={square}/>
                                    <IonLabel>Tab 3</IonLabel>
                                </IonTabButton>
                            </IonTabBar>
                        </IonTabs>
 <UnauthenticatedRoute exact path="/signup" component={SignUp} />
<Route exact path="/tab1">
    <Tab1/>
</Route>
<Route exact path="/tab2">
    <Tab2/>
</Route>
<Route path="/tab3">
    <Tab3/>
</Route>
<Route exact path="/">
    <Redirect to="/tab1"/>
</Route>
*/

/*
      <Route path="/tabs" render={() => <MainTabs />} />
                                <Route path="/account" component={Account} />
                                <Route path="/login" component={Login} />
                                <Route path="/signup" component={Signup} />
                                <Route path="/support" component={Support} />
                                <Route path="/tutorial" component={Tutorial} />
                                <Route path="/logout" render={() => {
                                    return <RedirectToLogin
                                        setIsLoggedIn={setIsLoggedIn}
                                        setUsername={setUsername}
                                    />;
                                }} />
                                <Route path="/" component={HomeOrTutorial} exact />
 */