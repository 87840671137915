export default (state, action) => {
    switch (action.type) {
        case 'entreprise' :
            return {
                ...state,
                entreprise: action.payload
            };
        case 'faqs' :
            console.log('set FAQ', action.payload)
            return {
                ...state,
                faqs: action.payload
            };
        case 'pole' :
            return {
                ...state,
                pole: action.payload
            };
        case 'logout' :
            return {
                faqs: [],
                initialized: false,
                risk: null,
                risks: [],
                evaluations: [],
                resolutions: [],
                positions: [],
                paginationArchiveStatus: '',
                paginationActiveStatus: '',
                paginationArchive: 0,
                paginationActive: 0,
                searchResolution: null,
                archive: false,
                activeList: [],
                archivedList: [],
                entreprises: [],
                poles: []
            };
        case 'init' :
            const {risks, evaluations} = action.payload;
            evaluations.sort((a, b) => {
                const aScore = parseInt(a.id.replace('f', ''));
                const bScore = parseInt(b.id.replace('f', ''));
                return aScore < bScore ? -1 : 1;
            });

            return {
                ...state,
                risks,
                evaluations
            };
        case 'risk' :
            return {
                ...state,
                risk: action.payload.value
            };
        case 'resolutionArchive' :
            return {
                ...state,
                archive: action.payload
            };
        case 'moreResolutions' :
            const pagination = state.archive ? 'paginationArchive' : 'paginationActive';
            return {
                ...state,
                [pagination]: state[pagination] + 1
            };
        case 'updateFiche' :


            const findArchive = state.archivedList.find(item => item.id === action.payload.id);
            const findActive = state.activeList.find(item => item.id === action.payload.id);

            console.log('action.payload.id',action.payload.id,'findArchive',findArchive,'findActive',findActive)


            let nextArchive = [...state.archivedList];
            let nextActive = [...state.activeList];

            if (findArchive) {
                nextArchive = state.archivedList.filter(item => item.id !== action.payload.id);
                nextActive = [findArchive, ...nextActive];
            } else if (findActive) {
                nextActive = state.activeList.filter(item => item.id !== action.payload.id);
                nextArchive = [findActive, ...nextArchive];
            }

            return {
                ...state,
                archivedList: nextArchive,
                activeList: nextActive
            }
        case 'deleteFiche' :
            return {
                ...state,
                archivedList: state.archivedList.filter(item => item.id !== action.payload.id),
                activeList: state.activeList.filter(item => item.id !== action.payload.id)
            }
        case 'addFiche' :
            const l = action.payload.archive ? 'archivedList' : 'activeList';
            return {
                ...state,
                [l]: [action.payload.fiche, ...state[l]]
            }
        case 'add' :
            const list = state.archive ? 'archivedList' : 'activeList';
            return {
                ...state,
                [list]: [...state[list], ...action.payload.items]
            }
        case 'addEntreprises' :
            return {
                ...state,
                entreprises: action.payload.entreprises
            }
        case 'addPoles' :
            return {
                ...state,
                poles: action.payload.poles
            }
        case 'addPositions' :
            return {
                ...state,
                positions: [...state.positions, ...action.payload.positions]
            }
        case 'paginationStatus' :
            const status = state.archive ? 'paginationArchiveStatus' : 'paginationActiveStatus';
            return {
                ...state,
                [status]: action.payload.value
            }
        case 'searchResolution' :
            const s = state.archive ? 'paginationArchive' : 'paginationActive';
            return {
                ...state,
                ...{archivedList: [], activeList: [], paginationArchive: -1, paginationActive: -1},
                ...action.payload.value === '' && {paginationStatus: 'more'},
                searchResolution: action.payload.value
            }

    }
}