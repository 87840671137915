import {getAuth,SAMLAuthProvider,signInWithRedirect,  signInWithEmailAndPassword} from 'firebase/auth'
import {IonContent, IonPage, IonButton, useIonRouter} from "@ionic/react";
import {useForm} from "react-hook-form";

import {Input} from '../components/form/Input';
import {useAuthState} from "../utils/firebase";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export const Login = () => {
    const router = useIonRouter();
    const { user } = useAuthState()
    useEffect(() => {
        if(user) {
            router.push('/dashboard');
        }
    }, [user]);

    const {register, handleSubmit, watch, control, formState: {errors}} = useForm({
        defaultValues: {
            "email": "",
            "password": ""
        },
        mode: "onChange"
    });

    /*const samlStaubli = async () => {
        const auth = getAuth();
        const provider = new SAMLAuthProvider('saml.saml-staubli');
        try {
            await signInWithRedirect(auth, provider);
        }catch (e) {
            console.log('e',e);
        }
    }*/

    const onSubmit = async (data: any) => {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, data.email, data.password);
        } catch (e:any) {
           alert(e.message)
        }
    }

    const {t} = useTranslation();

    return (
        <IonPage>
            <IonContent fullscreen>
                <a href={"https://whergo.fr/"} target={"_blank"} className={"absolute right-4 bottom-4 w-24"}>
                    <img src={"/assets/whergo.png"} className={"w-full h-auto"} />
                </a>
                <div className={"flex items-center justify-center min-h-full"}>
                    <div className={"grid grid-cols-12 w-full"}>
                        <div className={"col-span-8  col-start-3 md:col-span-4 md:col-start-5 lg:col-span-2 lg:col-start-6 flex flex-col mb-20"}>
                            <img src={"/assets/reperes.png"} className={"mb-2"} />
                            <p className={"text-center text-18 font-light"}>{t('login.desc')}</p>
                        </div>
                        <form className={"col-span-10 col-start-2 lg:col-span-4 lg:col-start-5"} onSubmit={handleSubmit(onSubmit)}>
                            <Input control={control} type={"email"} label={"Login"} name="email" rules={{required: true}}/>
                            <Input control={control} type={"password"} label={"Mot de passe"} name="password" rules={{required: true}}/>
                            <IonButton className={"uppercase mt-6"} type={"submit"} expand="block">{t('login.cta')}</IonButton>
                        </form>
                        {/*<div className={'col-span-12 flex items-center justify-center'}>
                            <button
                                onClick={() => router.push('/reset-password')}
                                className={"ion-activatable text-12 mt-2"}>
                                {t('login.resetcta')}
                            </button>
                        </div>*/}
                        <div className={'col-span-12 flex items-center justify-center my-10'}>
                            <IonButton  routerLink={"/company"}>
                                <span>{t('login.companyAccount')}</span>
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}