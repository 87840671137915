import {
    IonContent,
    IonPage,
} from '@ionic/react';
import {useAuthState} from "../utils/firebase";
import {ChangeEvent, useState} from "react";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

const Demo = () => {
    const {user} = useAuthState();

    const [imgSrc, setImgSrc] = useState(null)

    const upload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {

            const file = e.currentTarget.files[0];
            const extension = file.name.split(".").pop();
            const storage = getStorage();
            const storageRef = ref(storage,'/public/photos/' +  Date.now() + '.' + extension);

            uploadBytes(storageRef, file)
                .then((snapshot) => {
                    console.log('Uploaded a blob or file!',snapshot);
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                    });

                })
                .catch(e => {
                    console.log(e.code)
                })
        }

    }

    return (
        <IonPage>
            <IonContent>
                <input type={"file"}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => upload(e)}/>
            </IonContent>
        </IonPage>
    )
}
export default Demo;